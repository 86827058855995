<template>
  <b-row>
    <b-col cols="4">
      <FoldersList
        :folder-type="'email-template'"
      />
    </b-col>
    <b-col cols="8">
      <EmailsList />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import FoldersList from '@/views/components/folders-list/FoldersList.vue'
import EmailsList from '@/views/admin/emails/emails-list/EmailsList.vue'

export default {
  components: {
    BRow,
    BCol,
    FoldersList,
    EmailsList,
  },
}
</script>

<style>

</style>
